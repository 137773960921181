<template>
    <section class="section section_type_form-page is_new-design"
             :style="{background: 'url(https://api.xgps.us/customize/bg_login) no-repeat top/cover'}"
    >
        <div class="section__wrapper wrapper wrapper_has-logo">
            <div class="section__logo-wrap">
                <img src="https://api.xgps.us/customize/login_logo">
            </div>
            <div class="section__form-wrap">
                <form class="section__form pt__16" @submit.prevent>
                    <h1 class="section__title mb__32">
                        {{ $t('Hello!') }} <br>
                        {{ $t('Welcome back') }}
                    </h1>

                    <FieldGroup_input
                        :field-group_class="'mb__16'"
                        :id="'username'"
                        :label="$t('Login')"
                        :placeholder="$t('What_s your login?')"
                        :error="errors.username"
                        v-model.trim.lazy="username"
                    />
                    <FieldGroup_input
                        :field-group_class="'mb__16'"
                        :input_type="'password'"
                        :id="'password'"
                        :label="$t('Password')"
                        :placeholder="$t('Enter 6 characters or more')"
                        :error="errors.password"
                        v-model.trim="password"
                    />
                    <div class="horizontal-line mb__16">
                        <button class="button button_view_base button_theme_primary button_size_lg button_width_100p mr__8"
                                @click.prevent="onSubmit"
                        >
                            {{ $t('buttons.signin') }}
                        </button>
                        <template v-if="!isLite">
                        <button class="button button_view_base button_theme_white button_size_lg"
                                @click.prevent="onSubmit"
                        >
                            {{ $t('buttons.signup') }}
                        </button>
                        </template>
                    </div>

                    <div class="section__horizontal-line horizontal-line">
                        <div class="section__select-group select-group">
                            <select v-model="$root.$i18n.locale">
                                <option v-for="lang in getLangs" :key="`lang-${lang.id}`" :value="lang.id">
                                    {{ lang.name }}
                                </option>
                            </select>
                        </div>
                        <router-link class="section__link link" to="/requestPasswordReset">{{ $t('Forgot password?') }}</router-link>
                    </div>

                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from "vuex";
    import { required, email } from 'vuelidate/lib/validators'

    export default {
        name: 'SectionLogin',
        props: [],
        data() {
            return {
                username: '',
                password: '',
                errors: {
                    // username: 'Login cannot be blank.',
                    // password: 'Password cannot be blank.'
                }
            }
        },
        components: {},
        validations: {
            username: { required, email },
            password: { required },
        },
        computed: {
            ...mapGetters([
                "getLangs",
                "apiToken",
                "isLite",
            ]),
        },
        watch: {
            apiToken: {
                handler: function (newVl/*, oldVl*/) {
                    if(newVl > ' ' /*&& !(oldVl > ' ')*/){
                        this.$router.push('/units')
                    }
                }
            },
        },
        methods: {
            ...mapMutations([
                "updateTokens"
            ]),
            ...mapActions([
                "fetchTokens",
                "fetchAppProps",
            ]),
            onSubmit() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.errors = {}
                    if(!this.$v.username.required) {
                        //this.errors = {...this.errors, username: 'Login cannot be blank.'}
                        this.errors.username= 'Login cannot be blank.'
                    }else
                    if(!this.$v.username.email){
                        //this.errors = {...this.errors, username: 'Enter valid username'}
                        this.errors.username= 'Enter valid username'
                    }
                    if(!this.$v.password.required) {
                        //this.errors = {...this.errors, password: 'Password cannot be blank.'}
                        this.errors.password= 'Password cannot be blank.'
                    }
                    // return false // stop here if form is invalid
                }
                const formData = {
                    username: this.username,
                    password: this.password,
                    lang: this.$root.$i18n.locale,
                }
                localStorage.setItem(process.env.VUE_APP_PRODUCT+'.lang', this.$root.$i18n.locale)
                //console.log('onSubmit', formData, this)
                // this.$emit('login-user', formData)
                this.$api.site.login(formData)
                .then((response) => {
                    //console.log(response);
                    if(response.data && !response.data.error){
                        this.errors = {}
                        this.updateTokens(response.data)
                        this.fetchAppProps()
                    }else{
                        console.warn(response.data);
                        this.errors = {...response.data.errors}
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.errors = {username: ' ',password: ' '}
                });
                //console.log(this.apiToken)
            }
        },
        mounted() {
            //console.log('section_type_login', this.$i18n.locale, this.$root.$i18n.locale)
            if(this.apiToken > ' '){
                this.$router.push('/units')
            }

        },
    }
</script>

<style scoped>
@media all and (max-width: 592px){
    .section.is_new-design {
        overflow: scroll;
    }
}
</style>