<template>
    <SectionLogin />
</template>

<script>
import SectionLogin from '@/components/SectionLogin.vue'

export default {
    name: 'LoginPage',
    components: {
        SectionLogin
    },
}
</script>
